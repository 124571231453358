
import { defineComponent } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';

import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import ModalHelpButton from '@/components/ModalHelpButton.vue';

import DashboardMenu from '@/views/Dashboard/DashboardMenu.vue';
import InvoiceDashboardComponent from "@/views/Invoice/InvoiceDashboardComponent.vue";

export default defineComponent({
  name: 'InvoiceDashboardPage',
  components: { 
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    DashboardMenu,
    InvoiceDashboardComponent,
    ModalHelpButton,
  },
  setup (){
      return {}
    }
});

