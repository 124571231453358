
import { IonPage, IonContent, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import DashboardMenu from '@/views/Dashboard/DashboardMenu.vue';

import { defineComponent, } from 'vue';

import InvoicingDetailsComponent from '@/views/Invoice/InvoicingDetailsComponent.vue';
import ModalHelpButton from '@/components/ModalHelpButton.vue';

export default defineComponent({
  name: 'CostDetailsDashboardPage',
  components: { 
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    DashboardMenu,
    InvoicingDetailsComponent,
    ModalHelpButton,
  },

  setup () {
    return { }
  }
});

