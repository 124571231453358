import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_button, {
      fill: _ctx.fill,
      color: _ctx.color,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isModalOpen1 = true))
    }, {
      default: _withCtx(() => [
        (_ctx.selectedIcon)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              icon: _ctx.selectedIcon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
      ]),
      _: 1
    }, 8, ["fill", "color"]),
    _createVNode(_component_ion_modal, {
      "is-open": _ctx.isModalOpen1,
      onDidDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isModalOpen1 = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_header, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_toolbar, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_buttons, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isModalOpen1 = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Close")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_content, { class: "ion-padding" }, {
          default: _withCtx(() => [
            _createElementVNode("div", { innerHTML: _ctx.contentHtml }, null, 8, _hoisted_1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}