
  import { Link } from '@/components/PageDescription.vue';
  import { IonGrid, IonRow, IonCol, IonButton} from '@ionic/vue';
  import { images, square, triangle } from 'ionicons/icons';
  import { defineComponent, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAuthStore } from '@/store/authStore';
  import { useErrorBox } from "@/components/errorBox";

  export default defineComponent({
    name: 'AdminMenu',
    components: { 
        IonGrid,
        IonRow, 
        IonCol, 
        IonButton,      
    },
    setup(props, {slots}){
      const router = useRouter();
      const auth = useAuthStore();
      const { showError } = useErrorBox();

      onMounted(async () => {
        if(!auth.filteredRoles.includes("Admins"))
          await showError("Missing admins role", "Missing permission");
      });

      const links: Array<Link> = [{
        active: false,
        title: "Channel list",
        url: "/admin/channellist",
      },
      {
        active: false,
        title: "Tracking dashboard",
        url: "/admin/trackingDashboard",
      },
      {
        active: false,
        title: "Return reasons",
        url: "/admin/returnReasonsAdmin",
      },
      {
        active: false,
        title: "Return delivery types",
        url: "/admin/returnDeliverytype",
      },
      {
        active: false,
        title: "Invoicing browser",
        url: "/admin/invoice/browser",
      },
      {
        active: false,
        title: "Warehouse products",
        url: "/admin/warehouseProducts",
      },
      {
        active: false,
        title: "OGO services status",
        url: "/admin/servicestatus",
      }];

      const isActive = (url: string) =>{
        const currentPath = router.currentRoute.value.path;
        return currentPath == url;
      }

      const defaultSlotUsed = () => {
        const defaultSlot = slots?.default;
        return !!defaultSlot;
      }

      return {images, square, triangle,router,isActive, links, defaultSlotUsed,}
    }
  });
