import { RefundStatusEnum, RefundTypeEnum, ReturnSourceEnum } from "./Return";

export interface IItemWithActions {
    actions: ItemAction[];
    selected: boolean;
}

export class ItemActionTarget {
    id: string;
    text: string;

    constructor() {
        this.id = "";
        this.text = "";
    }
}

export class ItemAction {
    code: string;
    type: string;
    text: string;
    targetList: Array<ItemActionTarget>;
    targetId: string|undefined;

    constructor() {
        this.code = "";
        this.type = "";
        this.text = "";
        this.targetList = [];
    }
}




export class ReturnOrderDto implements IItemWithActions {
    returnId = "";
    merchantId = "";
    channelId = 0;
    warehouseCode = "";
    warehouseId = "";
    orderInfo: OrderInfo | undefined;
    refundStatus = RefundStatusEnum.NotAvailable;
    refundType = RefundTypeEnum.NotSelected;
    customer = new CustomerDetails;
    returnRows: ReturnRow[] = [];
    ruleId: string | undefined;
    deliveryTypeCode: string | undefined;
    cost = 0;
    refundedAmount: number | undefined;
    currency = "";
    customerComments = "";
    source = ReturnSourceEnum.Unknown;
    editTime: Date | undefined;
    createTime: Date | undefined;
    status = ReturnOrderStatusEnum.Unknown;
    returnedTime: Date | undefined;
    acceptedTime: Date | undefined;
    acceptedBy = "";
    trackingCodes: ReturnTracking[] = [];
    createdToMyOGO = false;
    actionNeeded = false;
    photos: PhotoLink[] = [];
    language = "";
    result = "";
    internalNote = "";
    ecomInfo: EcomInfo | undefined;
    selected = false;
    actions: ItemAction[] = [];
}

export class ReturnOrderWithActionsDto extends ReturnOrderDto implements IItemWithActions {
    actions: ItemAction[] = [];
    selected = false;

}

export class PhotoLink {
    id: string | undefined;
    url: string | undefined;
}

export class OrderInfo {
    orderId = "";
    warehouseCode = "";
    warehouseId = "";
    orderReference = "";
    orderNumber = "";
    integrationId: string | undefined;
    exchangeOrderId: string | undefined;
}

export class EcomInfo {
    externalId = "";
    status = EcomReturnStatusEnum.Unknown;
}

export enum EcomReturnStatusEnum {
    Unknown = "Unknown",
    Cancelled = "Cancelled",
    Created = "Created",
    Completed = "Completed",
}

export class CustomerDetails {
    email: string | undefined;
    phone: string | undefined;
    name: string | undefined;
    company: string | undefined;
    address1: string | undefined;
    address2: string | undefined;
    city: string | undefined;
    stateCode: string | undefined;
    countryCode: string | undefined;
    zip: string | undefined;
}

export class ReturnRow {
    code = "";
    name = "";
    informedQuantity = 0;
    returnedQuantity: number | undefined;
    customerReasonCode = ""
    warehouseReasonCode = "";
    refundUnitCost = 0;
    replacementProducts: ReplacementProduct[] | undefined;
}

export class ReturnTracking {
    trackingNumber = "";
    trackingUrl = "";
}

export enum ReturnOrderStatusEnum {
    Draft = "Draft",
    Requested = "Requested",
    Waiting = "Waiting",
    InTransit = "InTransit",
    Returned = "Returned",
    Cancelled = "Cancelled",
    Error = "Error",
    Closed = "Closed",
    Unknown = "Unknown"
}

export class ReplacementProduct {
    code: string | undefined;
    name: string | undefined;
    action = "";
}

export class ReturnOrderUpdateDto {
    warehouseCode: string | undefined;
    warehouseId: string | undefined;
    orderInfo: OrderInfo | undefined;
    refundStatus: RefundStatusEnum | undefined;
    customer: CustomerDetails | undefined;
    returnRows: ReturnRow[] | undefined;
    ruleId: string | undefined;
    deliveryTypeCode: string | undefined;
    cost: number | undefined;
    refundedAmount: number | undefined;
    currency: string | undefined;
    customerComments: string | undefined;
    source: ReturnSourceEnum | undefined;
    status: ReturnOrderStatusEnum | undefined;
    returnedTime: string | undefined;
    acceptedTime: string | undefined;
    acceptedBy: string | undefined;
    trackingCodes: ReturnTracking[] | undefined;
    createdToMyOGO: boolean | undefined;
    actionNeeded: boolean | undefined;
    photos: PhotoLink[] | undefined;
    language: string | undefined;
    result: string | undefined;
    internalNote: string | undefined;
    refundType: RefundTypeEnum | undefined;
}