import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createBlock(_component_ion_header, {
    class: _normalizeClass({hilight:(_ctx.hilight||(_ctx.actions?.length??0)>0)})
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              (_ctx.backButton)
                ? (_openBlock(), _createBlock(_component_ion_back_button, {
                    key: 0,
                    defaultHref: "/"
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_menu_button, { menu: "main" })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.pageName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: action.code
                }, [
                  ((action.targetList?.length ?? 0) > 0)
                    ? (_openBlock(), _createBlock(_component_ion_select, {
                        key: 0,
                        "ok-text": action.text,
                        placeholder: action.text,
                        color: action.type,
                        modelValue: action.targetId,
                        "onUpdate:modelValue": ($event: any) => ((action.targetId) = $event),
                        onIonChange: ($event: any) => (_ctx.clickAction(action))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(action.targetList, (target) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, {
                              key: target.id,
                              value: target.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(target.text), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["ok-text", "placeholder", "color", "modelValue", "onUpdate:modelValue", "onIonChange"]))
                    : _createCommentVNode("", true),
                  ((action.targetList?.length ?? 0) == 0)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        color: action.type,
                        onClick: ($event: any) => (_ctx.clickAction(action))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(action.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["color", "onClick"]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 3
          }),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, { onClick: _ctx.copyLink }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.linkOutline,
                    size: "small"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]),
              (_ctx.channels)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setMerchantSelectOpen(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.currentChannel?.name) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.chevronDownOutline,
                        size: "small"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_modal, {
            "is-open": _ctx.isMerchantSelectOpen,
            onDidDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setMerchantSelectOpen(false)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_buttons, { slot: "start" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setMerchantSelectOpen(false)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Close")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Select channel")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_ctx.channels)
                ? (_openBlock(), _createBlock(_component_ion_content, {
                    key: 0,
                    class: "ion-padding"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channels, (channel) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: channel.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(channel.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_button, {
                              disabled: channel.id === _ctx.currentChannel?.id,
                              onClick: ($event: any) => (_ctx.selectChannel(channel.id))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Select")
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["is-open"]),
          _createVNode(_component_ion_modal, {
            "is-open": _ctx.isUserModalOpen,
            onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setUserModalOpen(false)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_header, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_toolbar, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_buttons, { slot: "start" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Cancel")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Welcome " + _toDisplayString(_ctx.username), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_buttons, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, { strong: true }, {
                            default: _withCtx(() => [
                              _createTextVNode("Confirm")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_content, { class: "ion-padding" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, { position: "stacked" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Enter your name")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_input, {
                        ref: "input",
                        type: "text",
                        placeholder: "Your name"
                      }, null, 512)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["is-open"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["class"]))
}