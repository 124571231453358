

import { useConfigStore } from "@/store/configStore";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/vue";
import { informationCircleOutline, peopleOutline, helpOutline, } from "ionicons/icons";
import { computed, defineComponent, onMounted, ref } from "vue";

export type PredefinedColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'light'
  | 'medium'
  | 'dark';

  export type ButtonFill = 'clear' | 'outline' | 'solid' | 'default';

  export default defineComponent( {
    props: {
      text: { type: String, required: true },
      title: { type: String, required: true },
      code: { type: String, required: true },
      icon: { type: String, default: "helpOutline" },      
      color: { type: String, default: "primary" as PredefinedColors },
      fill: { type: String, default: "solid" as ButtonFill },
    },
    components: {
      IonButton,
      IonIcon,
      IonModal,
      IonHeader,
      IonToolbar,
      IonButtons,
      IonTitle,
      IonContent,
    },
    name: "ModalHelpButton",
    setup (props) {  
      const configStore = useConfigStore();
      const isModalOpen1 = ref(false);
      const contentHtml = ref("");

      onMounted(async () => {
        contentHtml.value = await configStore.getSnippet(props.code);
      });
      
      const selectedIcon = computed(() => {
        if(props.icon === "peopleOutline")
          return peopleOutline;

        if(props.icon === "helpOutline")
          return helpOutline;

        if(props.icon === "informationCircleOutline")
          return informationCircleOutline;

        return undefined;
      });

      return {
        isModalOpen1,
        selectedIcon,
        contentHtml,
      }
    }
  });
