import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ModalHelpButton = _resolveComponent("ModalHelpButton")!
  const _component_DashboardMenu = _resolveComponent("DashboardMenu")!
  const _component_InvoiceDashboardComponent = _resolveComponent("InvoiceDashboardComponent")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, { pageName: "Costs dashboard" }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DashboardMenu, null, {
              default: _withCtx(() => [
                _createVNode(_component_ModalHelpButton, {
                  color: "tertiary",
                  fill: "clear",
                  text: "This page",
                  title: "More info",
                  icon: "helpOutline",
                  code: "invoice-dashbard.help"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_InvoiceDashboardComponent),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}